.table{
    background-color: #637698;
    padding: 200px 40px;
    padding-top: 50px;
    position: relative;
    z-index: 1;
    @include responsive(md){
        padding: 100px 10px;
    }
    .view{
        cursor: pointer;
        &:hover{
            text-decoration: underline;
        }
    }
    .inner{
        max-width: 1440px;
        margin: 0 auto;
        .table-group{
            margin:40px 0px;
            border-left:1px solid #fff;
            border-right:1px solid #fff;
            border-top:1px solid #fff;
            
            &:last-child{
                margin-bottom: 0px;
            }
            .table-row{
                &:last-child{
                    margin-bottom:0;
                }
            }
        }
        .table-row{
            display: flex;
            padding: 16px;
            align-items: center;
            border-bottom: 1px solid white;
            margin-bottom: 10px;
            @include responsive(md){
                flex-wrap: wrap;
            }
            &.table-head{
                margin-bottom: 0px;
                border-radius: 20px 20px 0 0;
                &:not(.mobile){
                    @include responsive(md){
                        display: none;
                    }    
                }
            }
            &.mobile{
                display: none;
                text-align: center;
                border-radius: 20px 20px 0 0;
                @include responsive(md){
                    display: block;
                }
            }
            .cell{
                flex: 1;
                font-family: "Super Gothic";
                text-align: center;
                padding: 10px;
                color: white;
                &:not(.head){
                    font-size: 18px;
                    @include responsive(md){
                        font-size: 16px;
                    }
                }
                &.head{
                    color: white;
                    font-size: 30px;
                    @include responsive(md){
                        font-size: 25px;
                        justify-content: center;
                    }
                }
                &.bed-bath{
                    flex: 1.5;
                }
                &.price{
                    p{
                        text-decoration: line-through;
                    }
                    span{
                        text-decoration: none;
                    }
                }
                &.residence:not(.head){
                    font-size: 35px;
                    font-family: "cako";
                    span{
                        font-size: 26px;
                        margin-bottom: 8px;
                        display: none;
                        width: 100%;
                        text-align: center;
                        @include responsive(md){
                            display: block;
                        }
                    }
                    @include responsive(md){
                        flex: 100%;
                        padding-bottom: 15px;
                        margin-bottom: 10px;
                    }
                }
                span{
                    display: none;
                    @include responsive(md){
                        display: block;
                        width: 50%;
                        text-align: left;
                        font-size: 25px;
                    }
                }
                &:not(.residence){
                    @include responsive(md){
                        display: flex;
                        gap: 10px;
                        align-items: center;
                        flex: 100%;
                    }
                }
                &.type{
                    @include responsive(md){
                        display: none;
                    }
                }
                &.three-d-tour{
                    transition: 100ms;
                }
                &.floor-plan a{
                    color: #000;
                    text-decoration: none;
                    transition: 100ms;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}