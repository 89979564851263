
@font-face {
    font-family: 'Cako';
    src: url('../fonts/Cako-Regular.ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face{
    font-family: 'Super Gothic';
    src: url('../fonts/SuperaGothic-Book.otf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
