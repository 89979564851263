header{
    position: absolute;
    width: 100%;
    top: 54px;
    @include responsive(sm){
        top: 48px;
    }
    &.close{
        .menu-icon{
            span{
                &:last-child{
                    width: 100%;
                }
            }
        }
        .header-wrap{
            .links{
                transform: translateX(0px);
            }
        }
    }
    .menu-icon{
        width: 25px;
        height: 10px;
        display: flex;
        flex-direction: column;
        margin-right: 10px;
        justify-content: space-between;
        span{
            transition: 200ms;
            display: block;
            height: 2px;
            width: 100%;
            background-color: white;
            &:last-child{
                width: 80%;
            }
        }
    }
    @include responsive(lg){
        transition: background-color 0.15s ease-in-out;
        &.scrolled{
            background-color: #191d23;
        }
        position: fixed;
        z-index: 100;
        background-image: unset;
    }
    .header-wrap{
        padding: 30px 40px;
        display: flex;
        flex-direction: row-reverse;
        margin: 0 auto;
        z-index: 1000;
        justify-content: space-between;
        align-items: center;
        @include responsive(xl){
            padding: 30px;
        }
        .links{
            transition: 300ms ease-in-out;
            display: flex;
            align-items: center;
            width: 100%;
            @include responsive(lg){
                position: absolute;
                inset: 0;
                transform: translateX(-100%);
                background-color: #637698;
                height: 100vh;
                flex-direction: column;
                align-items: flex-start; 
                width: unset;
                padding-top: 150px;
            }
            .linkgroup0,.linkgroup2{
                width: calc(50% - 69px);
                display: flex;
                align-items: center;
                gap: 54px;
                @include responsive(lg){
                    width: unset;
                    flex-direction: column;
                    align-items: unset;
                    gap: unset;
                }
                .link{
                    color: #ffffff;
                    cursor: pointer;
                    text-transform: capitalize;
                    font-family: 'cako';
                    font-size: 22px;
                    text-decoration: none;
                    transition: color 300ms ease;
                    &.active{
                        color: #fff;
                        text-decoration: underline;
                    }
                    &:hover{
                        color: #fff;
                        text-decoration: underline;
                    }
                    @include responsive(xl){
                        font-size: 16px;
                    }
                    @include responsive(lg){
                        margin-right: 0px;
                        margin-left: 32px;
                        font-size: 30px;
                        color: white;
                        font-family: 'cako';
                        text-transform: capitalize;
                        font-weight: normal;
                        padding: 12px 0px;
                    }
                }
            }
            .linkgroup2{
                justify-content: flex-end;
                @include responsive(lg){
                    justify-content: flex-start;
                }

            }
            .linkgroup1{
                a{
                    cursor: pointer;
                    img{
                        width: 134px;
                        mix-blend-mode: screen;
                        @include responsive(xl){
                            width: 125px;
                        }
                    }
                    @include responsive(lg){
                        display: none;
                    }
                }
            }
        }
        .inquire{
            display: none;

            .link{
                color: #ffffff;
                cursor: pointer;
                text-transform: capitalize;
                font-family: 'cako';
                font-size:22px;
                text-decoration: none;
                transition: color 300ms ease;
                &:hover{
                    color: #fff;
                    text-decoration: underline;
                }

            }
            @include responsive(lg){
                display: block;
            }
        }
        .logo-wrap{
            a{
                cursor: pointer;
                .logo{
                    width: 158px;
                    mix-blend-mode: screen;
                    display: none;
                    @include responsive(xl){
                        width: 125px;
                    }
                    @include responsive(lg){
                        display: block;
                        width: 72px;
                    }
                }
            }
        }
        .menu-button{
            user-select: none;
            display: none;
            cursor: pointer;
            @include responsive(lg){
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;
                z-index: 99;
                span{
                    user-select: none;
                    font-size:22px;
                    color: #fff;
                    font-family: 'cako';
                }
            }
        }
    }
}