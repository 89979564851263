// TWO-COLUMN VARIANTS STYLES

.white{
    background-color: #fff;

   .inner{

    .content-wrap{

        .heading{
            color: #637697;
        }
        .text{
            color: #000;
            
        }
    }
   }
}
.blue{
    background-color: #637697;

    .inner{
        .content-wrap{

            .heading{
                color: #feeddc;
            }
            .text{
                color: #fff;
            }
            .price{
                color: #fff !important;
            }
            .cta{
                color: #fff !important;
            }
        }
    }
}
.row-reverse{
    flex-direction: row-reverse;
    .content-wrap{
        align-items: flex-end;
        .heading{
            text-align: right;
        }
        .text{
            text-align: right;
        }
        .price{
            text-align: right;
        }
        .cta{
            text-align: right;
        }
    }
}



// GENERAL TWO COLUMN STYLES

.two-column{
    padding: 80px 20px;
    position: relative;
    z-index: 1;

    .inner{
        max-width: 1440px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        gap: 50px;

        @media(max-width:767px){
            gap: 30px;           
            flex-direction: column !important;
        }

        .content-wrap{
            width: calc(50% - 25px);
            display: flex;
            flex-direction: column;
            justify-content: center;

            @media(max-width:767px){
                width: 100%;
                align-items: center;    
            }

            .heading{
                font-size: 100px;
                text-transform: capitalize;
                margin-bottom: 40px;
                font-family: 'Cako';
                font-weight: normal;
                line-height: 1;
                font-style: normal;

                @media(max-width:1200px){
                    font-size: 60px;   
                }
                @media(max-width:991px){
                    font-size: 45px;   
                }
                @media(max-width:767px){  
                    text-align: center;                 
                }
            }
            .text{
                font-size: 18px;
                margin-bottom: 20px;
                font-family: 'Super Gothic';
                font-weight: normal;
                max-width: 500px;
                font-style: normal;
                @media(max-width:767px){  
                    text-align: center;                 
                }
            }
            .price{
                color: #92514d;
                font-size: 28px;
                font-family: 'Cako';
                font-weight: normal;
                font-style: normal;
                margin-top: 26px;

                @media(max-width:991px){
                    font-size: 24px;   
                }
                @media(max-width:767px){
                    font-size: 26px;   
                    text-align: center;
                }
            }
            .cta{
                color: #92514d;
                font-size: 28px;
                font-family: 'Cako';
                font-weight: normal;
                font-style: normal;
                margin-top: 26px;
                &:hover{
                    text-decoration: underline;
                }

                @media(max-width:991px){
                    font-size: 24px;   
                }
                @media(max-width:767px){
                    font-size: 26px;   
                    text-align: center;
                }
            }
        }
        .image-wrap{
            width: calc(50% - 25px);
            
            @media(max-width:767px){
                width: 100%;
            }
            
            
            .image{
                width: 100%;
                height: 100%;
            }
        }
    }
    
}