.specials{
    position: absolute;
    width: 100%;
    display: flex;
    background-color: #637698;
    top: 0;
    padding: 15px;
    transition: right 1s ease;
    z-index: 65;
    @include responsive(lg){
        position: fixed;
    }
    &.close{
        display: none;
    }
    .special-banner{
        text-align: center;
        position: relative;
        width: 100%;
        .text-wrap{
            .offer-text{
                font-family: 'cako';
                color: white;
                font-size: 20px;
                @include responsive(sm){
                    font-size: 16px;
                }
                @include responsive(xs){
                    font-size: 14px;
                }
            }
            
        }
    }
}