.map{
    height: 600px;
    .map-popup{
        padding-top: 5px;
        text-align: center;
        font-family: 'Super Gothic';
        line-height: 1;
        font-size: 14px;
    }
    @include responsive(sm){
        height: 400px;
    }
}