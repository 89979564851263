.parrallax-banner {
    position: relative;

    img {
        width: 100%;
        height: 100%;
        position: fixed;
        height: 100vh;
        object-fit: cover;
        z-index: -2 ;
    }

}

.parrallax-bottom-text {
    order: 1;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding: 300px 40px 200px 40px;
    width: 100%;
    margin-bottom: -10px;
    z-index: 99;

    .content {
        text-align: center;
        color: #fff;
        @include responsive(xs) {
            max-width: 480px;
        }

        .heading {
            font-family: 'DirtyLine';
            font-size: 80px;
            letter-spacing: -1.8px;
            font-weight: 500;

            @include responsive(md) {
                font-size: 60px;
            }

            @include responsive(sm) {
                font-size: 50px;
            }

            @include responsive(xs) {
                font-size: 36px;
            }

            span {
                display: inline-block;
                font-size: 40px;
                transform: translateY(-10px);
                z-index: 1;

                @include responsive(md) {
                    font-size: 30px;
                }

                @include responsive(sm) {
                    font-size: 24px;
                    transform: translateX(0);
                }

                @include responsive(xs) {
                    font-size: 40px;
                }
            }
        }

        .description {
            margin-top: 20px;
            font-size: 22px;
            font-family: 'Super Gothic';
            font-weight: 100;
            max-width: 1500px;

            @include responsive(md) {
                font-size: 18px;
            }

            @include responsive(sm) {
                font-size: 16px;
            }
        }

        a {
            display: inline-block;
            font-size: 16px;
            color: #fff;
            cursor: pointer;
            font-family: 'Super Gothic';
            border: 1px solid #fff;
            text-decoration: none;
            padding: 20px 30px;
            margin-top: 40px;

            &:hover {
                transition: 0.3s ease;
                color: #F4E9D7;
                border-color: #F4E9D7;
            }
        }
    }
}